.root {
  align-items: center;
  background: #ffffff;
  border-radius: 40px;
  box-shadow: 0 1px 9px rgba(41, 45, 52, 0.07);
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 100%;
  padding: 24px 0 40px;
  width: 380px;

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;

    input {
      height: 35px;
      width: 250px;
    }
  }
}
