.root {
}

.modal {
  min-width: unset;
  width: 300px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.invitationRow {
  align-items: center;
  display: flex;
  gap: 6px;
  padding-bottom: 7px;
  padding-top: 7px;
}

.showMore {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  gap: 8px;
  justify-content: flex-end;
  margin-bottom: 8px;
  margin-top: 4px;
}
