.page {
  background-color: #fdfdfd;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr auto;
  grid-template-rows: 49px auto;
  padding: 10px;

  :global {
    .Table {
      table {
        table-layout: fixed;
      }
    }
  }
}

.header {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  grid-column: 1 / 2;
  height: 49px;
  justify-content: space-between;
  padding: 10px 0;

  :global {
    .Button {
      flex-shrink: 0;
    }
  }
}

.table {
  grid-column: 1 / 2;
}

.sidePanelSection {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 129px minmax(0, 1fr);
  padding: 8px 0 12px;
  row-gap: 10px;
}

.sidePanelSection__main {
  border-bottom: 1px solid rgba(242, 242, 242, 0.56);
  composes: sidePanelSection;
  margin-bottom: 16px;
}

.materials {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.detailsModalBody {
  outline: none;
}

.detailsModal_actions {
  border-bottom: 1px solid #eff0f3;
  display: flex;
  gap: 14px;
  justify-content: flex-end;
  margin-bottom: 6px;
  padding-bottom: 12px;
}

@media (min-width: 768px) {
  .page {
    :global {
      .Table {
        table {
          table-layout: auto;
        }
      }
    }
  }
}
