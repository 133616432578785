.popover {
  font-size: 14px;
  padding-left: 0;
  padding-right: 0;
  width: 200px;
}

.pricesSection {
  padding: 8px 16px 0;
}

.availablePrices {
  padding-bottom: 12px;
  padding-top: 8px;
}

.addNew {
  align-items: center;
  display: grid;
  gap: 12px;
  grid-template-columns: minmax(0, 1fr) auto;
  padding-bottom: 8px;
}

.sectionTitle {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
}

.price {
  align-items: center;
  border-bottom: 1px solid rgba(242, 242, 242, 0.56);
  display: flex;
  height: 28px;
  justify-content: space-between;
  padding: 0 6px;
}

.priceBookSection {
  border-top: 1px solid #d8d8d8;
  gap: 8px;
  padding: 8px 16px 0;
}

.priceBookEntry {
  align-items: center;
  border-bottom: 1px solid rgba(242, 242, 242, 0.56);
  display: flex;
  height: 36px;
  justify-content: space-between;
  padding: 0 6px;
}

.priceDropdown {
  border: 1px solid rgba(226, 226, 226, 0.6);
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  gap: 8px;
  height: 28px;
  line-height: 20px;
  padding: 4px 6px;
  width: 50px;
}
