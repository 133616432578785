.page {
  background-color: #fdfdfd;
  display: flex;
  flex-direction: column;
  padding: 10px;
  row-gap: 10px;

  :global {
    .SidePanel {
      grid-column: 3 / 3;
      margin-left: 8px;
    }

    .Table {
      table {
        table-layout: fixed;
      }

      .Table_content {
        .Table_row--body {
          height: 51px;
        }
      }
    }
  }

  .filters {
    display: none;
  }
}

.header {
  align-items: center;
  display: flex;
  grid-column: 1 / 3;
  height: 49px;
  justify-content: end;
  padding: 10px 0;
}

.filters_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 12px;
}

.filters_clearBtn {
  * {
    color: #292d34;
  }
}

.table {
  grid-column: 2 / 3;

  table {
    table-layout: fixed;
  }
}

.LessonDate {
  align-items: center;
  display: flex;
  font-size: 14px;
  gap: 8px;
  line-height: 21px;
}

.LessonDate_hours {
  color: #7c828d;
}

.homeworkCol {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.sidePanelActions {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

// TODO: Duplicated
.sidePanelSection {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 129px minmax(0, 1fr);
  padding: 8px 0 12px;
  row-gap: 10px;
}

.sidePanelSection__main {
  border-bottom: 1px solid rgba(242, 242, 242, 0.56);
  composes: sidePanelSection;
  margin-bottom: 16px;
}

.materials {
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.detailsModal_body {
  font-size: 14px;
  outline: none;
}

.detailsModal_actions {
  border-bottom: 1px solid #eff0f3;
  margin-bottom: 12px;
  padding-bottom: 16px;
}

.openFiltersBtn {
  margin-right: auto;
}

.FiltersModal {
  min-width: 300px;
  width: 300px;
}

.shareAction {
  margin-right: 10px;

  svg {
    width: 14px;
  }
}

@media (min-width: 768px) {
  .page {
    :global {
      .Table {
        table {
          table-layout: auto;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .page {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr) auto;
    grid-template-rows: 49px auto;
    padding: 10px;
    row-gap: 10px;

    .filters {
      display: block;
      grid-column: 1 / 2;
      margin-right: 12px;
      padding: 0 16px;
      width: 240px;
    }

    .openFiltersBtn {
      display: none;
    }
  }
}

.page {
  @media (max-width: 1400px) {
    :global {
      .SidePanel {
        width: 350px;
      }
    }
  }
}
