.root {
  cursor: pointer;
  display: inline-flex;
}

.desc {
  font-size: 14px;
  line-height: 21px;
  max-width: 300px;
}
