.root {
  left: 240px;
  position: absolute;
  width: 280px;
}

.icon {
  left: 8px;
  position: absolute;
  top: 54%;
  transform: translateY(-50%);
}

.input {
  padding-left: 30px;
}

.list {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.popover {
  max-height: 250px;
  overflow-y: auto !important;
  padding-left: 10px;
  padding-right: 10px;
}

.item {
  border-bottom: 1px solid #f2f2f2;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  padding: 4px 2px;
  position: relative;
  width: 100%;

  &:hover {
    background: rgba(0, 0, 0, 0.01);
  }
}

.contact {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.actions {
  align-items: center;
  background: #ffffff;
  border-left: 1px solid #eff0f3;
  display: none;
  height: 28px;
  padding: 0 12px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.item:hover {
  .actions {
    display: flex;
  }
}

.newLessonBtn {
  align-items: center;
  border: 1px dashed #292d34;
  border-radius: 50px;
  composes: btn-icon from "/src/index.scss";
  display: inline-flex;
  height: 22px;
  justify-content: center;
  width: 22px;
}
