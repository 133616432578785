.root {
}

.modal {
  font-weight: 400;
  width: 450px;

  :global {
    .ModalContent {
      padding: 0;
    }
  }
}

.section {
  border-bottom: 1px solid #eff0f3;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 24px;
}

.materialsSection {
  padding: 16px 24px;
}
