.root {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.input {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  color: #292d34;
  font-size: 14px;
  min-height: 44px;
  outline: none;
  padding: 4px 8px;
  resize: vertical;

  &::placeholder {
    color: #aeaeae;
    font-size: 14px;
  }
}

.label {
  color: #292d34;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}
