.root {
  align-items: center;
  cursor: help;
  display: flex;
  font-size: 15px;
  gap: 8px;
}

.desc {
  font-size: 14px;
  line-height: 21px;
  max-width: 300px;
}
