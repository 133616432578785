.app {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto 1fr;

  :global {
    .Menu {
      grid-row: span 2;
    }
  }

  main {
    grid-column: 1;
    grid-row: 2;
  }
}

@media (min-width: 1200px) {
  .app {
    grid-template-columns: auto minmax(0, 1fr);

    main {
      grid-column: 2;
      grid-row: 2;
    }
  }
}
