.root {
}

.dates {
  border-bottom: 1px solid #eff0f3;
  color: #292d34;
  column-gap: 24px;
  display: grid;
  font-size: 15px;
  font-weight: 500;
  grid-template-columns: 40px 1fr;
  line-height: 22px;
  padding-bottom: 8px;
  row-gap: 8px;
}

.dates_label {
  color: rgba(41, 45, 52, 0.9);
  font-weight: 400;
}

.applications {
  padding-top: 12px;
}

.applications_header {
  color: #909498;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 8px;
}

.applications_list {
}

.application {
  border-bottom: 1px solid rgba(242, 242, 242, 0.56);
  display: grid;
  font-size: 14px;
  gap: 8px;
  grid-template-columns: 1fr auto auto;
  padding: 4px 6px;
}

.application_date {
  font-size: 14px;
  line-height: 21px;
  text-align: right;
}

.application_time {
  color: #909498;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
}

.noApplicationsInfo {
  font-size: 13px;
  line-height: 20px;
  padding: 4px 0;
}
