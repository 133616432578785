.root {
  align-items: center;
  border-radius: 3px;
  display: inline-flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  line-height: 18px;
  padding: 1px 2px;
}

.neutral {
  background-color: #909498;
  color: #ffffff;
}

.primary {
  background-color: #197bbd;
  color: #ffffff;
}

.danger {
  background-color: #b24b43;
  color: #ffffff;
}

.orange {
  background: rgba(251, 179, 90, 0.1);
  border: 1px solid #e99455;
  color: #292d34;
}
