.root {
  align-items: center;
  background: rgb(41 45 52 / 90%);
  color: white;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  gap: 20px;
  line-height: 1.4;
  padding: 12px 22px;

  a {
    color: #3a9bdb;
    font-weight: 600;
    text-decoration: underline;
  }

  svg {
    flex-shrink: 0;
    height: 1.4em;
    width: 1.4em;
  }
}

@media (max-width: 360px) {
  .root {
    padding: 10px 18px;
  }
}

@media (min-width: 768px) {
  .root {
    font-size: 17px;
    gap: 22px;
    justify-content: center;
    padding: 10px 32px;
  }
}
