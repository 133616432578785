.root {
  align-items: center;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  display: inline-flex;
  gap: 6px;
  outline: none;

  svg,
  img {
    flex-shrink: 0;
  }
}

.neutral {
  background: #909498;
  color: #ffffff;
}

.danger {
  background: #b24b43;
  color: #ffffff;
}

.primary {
  background: #91b5ac;
  color: #ffffff;
}

.secondary {
  background: #197bbd;
  color: #ffffff;
}

.dark {
  background: rgba(41, 45, 52, 0.8);
  color: #ffffff;
}

.default {
  font-size: 14px;
  font-weight: 500;
  height: 34px;
  line-height: 1;
  padding: 0 12px;
}

.outlined.primary {
  background: #ffffff;
  border: 1px solid rgba(145, 181, 172, 0.7);
  color: #91b5ac;
}

.outlined.secondary {
  background: #ffffff;
  border: 1px solid #197bbd;
  color: #197bbd;
}

.outlined.dark {
  background: #ffffff;
  border: 1px solid rgba(41, 45, 52, 0.8);
  color: rgba(41, 45, 52, 0.8);
}

.outlined.neutral {
  background: #ffffff;
  border: 1px solid #909498;
  color: #909498;
}
