.root {
  align-items: center;
  border: 1px solid #aaaaaa;
  color: #292d34;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  gap: 10px;
  height: 38px;
  justify-content: center;
  line-height: 21px;
  padding: 0 16px;
  transition: all 150ms;

  &:hover {
    color: darken(#000000, 100%);
  }
}
