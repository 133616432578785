.root {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  gap: 8px;
  line-height: 21px;

  :global {
    input[type="checkbox"] {
      appearance: none;
      background-color: #ffffff;
      border: 1px solid #292d34;
      border-radius: 0;
      color: #197bbd;
      cursor: pointer;
      display: grid;
      flex-shrink: 0;
      font: inherit;
      height: 14px;
      margin: 0;
      outline: none;
      place-content: center;
      width: 14px;
    }

    input[type="checkbox"]:checked {
      border: 1px solid #197bbd;
      color: #197bbd;
    }

    input[type="checkbox"]::before {
      box-shadow: inset 1em 1em #197bbd;
      content: "";
      height: 6px;
      transform: scale(0);
      transform-origin: center;
      transition: 120ms transform ease-in-out;
      width: 6px;
    }

    input[type="checkbox"]:checked::before {
      transform: scale(1);
    }

    input[type="checkbox"]:disabled {
      border-color: #909498;
      color: #909498;
      cursor: not-allowed;

      &::before {
        box-shadow: inset 1em 1em #909498;
      }
    }
  }
}

.disabled {
  color: #909498;
  cursor: not-allowed;
}
