.root {
  align-items: center;
  border: 1px solid rgba(233, 235, 240, 0.5);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  color: #2a2e34;
  display: grid;
  font-size: 13px;
  gap: 10px;
  grid-template-columns: 18px 1fr 20px;
  line-height: 20px;
  padding: 2px 8px 2px 16px;
}

.unseen {
  background: rgba(255, 243, 235, 0.6);

  &:hover {
    .deleteAction {
      display: inline-flex;
    }

    .unseenBulb {
      display: none;
    }
  }

  .deleteAction {
    display: none;
  }

  .unseenBulb {
    display: inline-block;
  }
}

.unseenBulb {
  background: #ac3131;
  border-radius: 50%;
  display: none;
  height: 8px;
  margin-left: auto;
  margin-right: auto;
  width: 8px;
}

.deleteAction {
  composes: btn-icon from "/src/index.scss";
  margin-left: auto;
  margin-right: auto;
}
