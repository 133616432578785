.root {
  display: flex;
  flex-direction: column;
  gap: 2px;

  // INFO: Hide arrows for [type="number"]
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.input {
  appearance: none;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  caret-color: #292d34;
  color: #292d34;
  font-size: 14px;
  outline: none;
  padding: 4px 8px;

  &::placeholder {
    color: #aeaeae;
    font-size: 14px;
  }
}

.disabled {
  background: rgba(144, 148, 152, 0.1);
  border: 1px solid #e2e2e2;
}
