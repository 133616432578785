.root {
  background-color: #197bbd;
  border: 1px solid #ffffff;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 11px;
  height: 100%;
  padding: 4px 6px;
  position: absolute;
  transition: opacity 0.3s;
  width: 100%;
  z-index: 1;

  &:hover {
    opacity: 0.8;
  }
}

.hours {
  color: #197bbd;
  display: none;
  font-size: 12px;
  font-weight: 600;
  margin-left: auto;
  position: absolute;
  right: 3px;
  top: 3px;
}

.title {
  display: none;
  font-weight: 500;
  line-height: 20px;
  max-width: calc(100% - 66px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.footer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  & > :first-child {
    display: none;
  }
}

.counter {
  font-size: 16px;
  padding: 4px 6px 3px;
}

@container (min-width: 180px) {
  .root {
    font-size: 12px;
  }

  .counter {
    height: 16px;
    width: 18px;
    font-size: 14px;
    padding: 1px 2px;
  }

  .footer {
    gap: 8px;
    justify-content: flex-start;
    height: auto;

    & > :first-child {
      display: block;
    }
  }
}

@container (min-width: 220px) {
  .root {
    background-color: #ffffff;
    border: 1px solid #197bbd;
    font-size: 13px;
    border-left: 9px solid #197bbd;
    width: 90%;
    color: #292d34;
    justify-content: space-between;
  }

  .hours {
    display: block;
  }

  .title {
    display: inline-block;
  }
}
