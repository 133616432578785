.root {
}

.modal {
  min-height: 550px;
  width: 1100px;

  :global {
    .ModalContent {
      padding: 0;
    }
  }
}

.header {
  align-items: flex-start;
  border-bottom: 1px solid #eff0f3;
  display: flex;
  flex-direction: column;
  padding: 22px 16px;

  .header_left {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    padding: 0 0 32px;
  }

  .header_right {
    align-items: flex-start;
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    gap: 32px;
    height: 100%;
  }

  .studentName {
    align-items: center;
    display: flex;
    font-size: 24px;
    gap: 12px;
    line-height: 36px;

    * {
      color: #292d34;
    }
  }

  .header_actions {
    align-items: center;
    display: flex;
    gap: 12px;
  }

  .header_date {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    gap: 5px;
    line-height: 18px;
    padding: 0 12px 0 0;
  }

  .header_dateLabel {
    color: #bbbbbb;
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
  }

  .header_contact {
    display: none;
  }
}

.main {
  display: flex;
  flex-direction: column;
}

.left {
  align-items: flex-start;
  border-right: 1px solid #eff0f3;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 32px;
  overflow: hidden;
  padding: 16px 16px 32px;
}

.right {
  background: rgba(187, 187, 187, 0.04);
  border-top: 1px solid #dcdfe4;
  flex: 0 0 460px;
}

.sectionHeader {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  gap: 6px;
  line-height: 24px;
}

.descPopover {
  font-size: 14px;
  line-height: 1.3;
  max-width: 350px;
}

.noAccountPopover {
  font-size: 14px;
  line-height: 1.3;
  padding: 16px 12px;
  width: 350px;
}

.noAccountPopover_header {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 8px;
}

.noAccountPopover_actions {
  margin-top: 12px;
  text-align: right;
}

@media (min-width: 1200px) {
  .main {
    flex-direction: row;
  }

  .left {
    gap: 24px;
    padding: 16px 32px 32px 24px;
  }

  .right {
    border-top: none;
  }

  .header {
    align-items: center;
    border-bottom: 1px solid #eff0f3;
    flex-direction: row;
    height: 80px;
    padding: 0 24px;

    .header_left {
      align-items: center;
      border-right: 1px solid #eff0f3;
      flex-direction: row;
      height: 100%;
      justify-content: space-between;
      padding: 0 30px 0 0;
    }

    .header_right {
      align-items: center;
      flex-wrap: nowrap;
      gap: 24px;
      padding: 0 30px;
    }

    .header_date {
      border-right: 1px solid #efeef0;
    }

    .header_contact {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      font-weight: 500;
      gap: 5px;
      line-height: 18px;
      text-decoration-line: underline;

      * {
        color: #0f4264;
      }
    }
  }
}
