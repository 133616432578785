.root {
  max-width: 100%;
  width: 100%;
}

.tabs {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  gap: 16px;
  line-height: 24px;
  margin-bottom: 12px;
}

.tab {
  border-bottom: 2px solid transparent;
  color: #909498;
  cursor: pointer;
  gap: 10px;
  height: 32px;
  transition: color 0.3s;

  &:hover {
    color: darken(#909498, 20%);
  }
}

.tab__active {
  border-color: #197bbd;
  color: #292d34;
  composes: tab;

  &:hover {
    color: #292d34;
  }
}

.lessonSubLists {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
