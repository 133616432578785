.root {
  :global {
    .react-calendar {
      max-width: 400px;
      width: 100%;
    }

    .react-calendar__month-view__days {
      row-gap: 6px;
    }

    .react-calendar__month-view__weekdays__weekday {
      color: #000000;
      font-size: 10px;
      font-weight: 400;
      line-height: 15px;
      opacity: 0.5;
      padding-bottom: 6px;
      text-align: center;
      text-transform: uppercase;

      abbr {
        text-decoration: none;
      }
    }

    .react-calendar__tile {
      appearance: none;
      background-color: white;
      border: none;
      color: #292d34;
      cursor: pointer;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      outline: none;
      padding-bottom: 2px;
      padding-top: 2px;
      transition: color 0.3s;

      &:hover {
        color: #197bbd;
      }
    }

    .react-calendar__tile--active {
      color: #197bbd;
      font-weight: 700;
      text-decoration: underline;
    }

    .react-calendar__tile--now {
      font-weight: 700;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      opacity: 0.5;
    }

    .react-calendar__navigation {
      align-items: center;
      display: flex;
      margin-bottom: 16px;

      .react-calendar__navigation__label {
        pointer-events: none;
      }

      button {
        appearance: none;
        background-color: white;
        border: none;
        color: #292d34;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        outline: none;
      }

      .react-calendar__navigation__prev2-button,
      .react-calendar__navigation__next2-button {
        display: none;
      }
    }
  }
}
