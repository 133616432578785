.root {
  border-right: 1px solid #eff0f3;
  color: #292d34;
  font-size: 14px;
  padding: 30px 12px;
}

.all {
  border-bottom: 1px solid #eff0f3;
  margin-bottom: 16px;
  padding: 0 0 8px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.item {
}

.itemName {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 6px;
  min-height: 20px;

  &:hover {
    background: rgba(25, 123, 189, 0.02);
  }

  svg {
    flex-shrink: 0;
  }
}

.subItems {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-left: 8px;
  padding-top: 6px;
}
