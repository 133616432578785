.root {
  align-items: center;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
  height: 30px;
  justify-content: center;
  outline: none;
  padding: 0;
  transition: transform 0.3s;
  width: 30px;

  &:hover {
    transform: translateY(-1px);
  }

  svg {
    width: 18px;
  }
}
