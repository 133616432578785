.modal {
  width: 400px;

  :global {
    .ModalContent {
      max-height: unset;
      max-height: calc(100svh - 48px /* header */ - 59px /* footer */ - 120px);
      overflow: auto;
    }
  }
}

.footer {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.btn {
  font-size: 14px;
  line-height: 1;
  padding: 5px 6px;

  path {
    fill: #ffffff;
  }
}
