.root {
  align-items: center;
  border-bottom: 1px solid rgba(239, 240, 243, 0.5);
  cursor: pointer;
  display: grid;
  font-size: 14px;
  grid-template-columns: 25px 1fr 40px;
  line-height: 21px;
  padding: 2px;
  width: 100%;

  &:hover {
    background: rgba(25, 123, 189, 0.02);
  }
}

.root__checked {
  background: rgba(25, 123, 189, 0.05);
  composes: root;

  &:hover {
    background: rgba(25, 123, 189, 0.05) !important;
  }
}

.colName {
  margin-right: 12px;
  overflow: auto;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
}

.name {
  &:hover {
    text-decoration: underline;
  }
}

.recursiveNavigation {
  color: #7c828d;
  font-size: 12px;

  :global {
    .RecursiveNavigation_name {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.condensed {
  grid-template-columns: 25px 1fr 40px !important;
}

@media (min-width: 768px) {
  .root {
    grid-template-columns: 25px 3fr repeat(1, 1fr) 40px;
  }

  .name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
