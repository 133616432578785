.root {
  color: #292d34;
  column-gap: 24px;
  display: grid;
  font-size: 15px;
  font-weight: 500;
  grid-template-columns: 40px 1fr;
  line-height: 22px;
  max-width: 410px;
  padding-bottom: 8px;
  row-gap: 8px;
}

.label {
  color: rgba(41, 45, 52, 0.9);
  font-weight: 400;
}

.footer {
  align-items: center;
  display: flex;
  gap: 14px;
  justify-content: flex-end;
}
