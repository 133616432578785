.root {
  position: relative;

  input {
    caret-color: transparent;
    cursor: pointer;
  }
}

.svg {
  bottom: 13px;
  position: absolute;
  right: 12px;
}

.dropdown {
  padding-left: 8px;
  padding-right: 8px;
}

.option {
  margin: -4px -6px;
  padding: 4px 6px;
}
