.ql-container.ql-snow {
  border: 1px solid #e9ebf0;
}

.ql-toolbar.ql-snow {
  border: 1px solid #dcdfe4;
}

.ql-container {
  font-size: 14px;
  min-height: 100px;
}

button.ql-header svg {
  display: none;
}

.ql-editor ol {
  padding-left: 18px;
}

.ql-editor ul {
  padding-left: 13px;
}

.ql-editor ol li,
.ql-editor ul li {
  padding-left: 0 !important;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 10px;
}

.ql-snow.ql-toolbar button {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.ql-header[value="1"]:after {
  color: #444;
  content: "H1";
  font-size: 15px;
  font-weight: 600;
}

.ql-header[value="2"]:after {
  color: #444;
  content: "H2";
  font-size: 15px;
  font-weight: 600;
}

.ql-header[value="3"]:after {
  color: #444;
  content: "H3";
  font-size: 15px;
  font-weight: 600;
}

.ql-header[value="4"]:after {
  color: #444;
  content: "H4";
  font-size: 15px;
  font-weight: 600;
}

.ql-header[value="5"]:after {
  color: #444;
  content: "H5";
  font-size: 15px;
  font-weight: 600;
}

.ql-header[value="6"]:after {
  color: #444;
  content: "H6";
  font-size: 15px;
  font-weight: 600;
}

button.ql-header.ql-active {
  color: #3891d0;
}
