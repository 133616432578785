.WeekView {
  display: flex;
}

.root {
  background: #ffffff;
  max-width: 100%;
}

.root_today {
  background: rgb(25 123 189 / 1%);
  border: 1px solid rgb(25 123 189 / 30%);
  border-top: none;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  width: 100%;
}

.days[data-cols="5"] {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.day {
  border-bottom: 1px solid #292d344f;
  border-right: 1px solid #e9ebf0;
  display: flex;
  height: 60px;
  position: relative;

  &:last-of-type {
    border-bottom: none;
  }
}

// NOTE: Last column
.root:last-of-type {
  .day {
    border-right: none;
  }
}

.day__hour {
  flex-shrink: 0;
  height: 100%;
  position: relative;
  text-align: center;
  width: 37px;
}

.day__content {
  container: scheduler-cell / inline-size;
  flex: 1;
  height: 60px;
  max-width: 100%;
  position: relative;
}

.hour {
  background: white;
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  padding: 0 4px;
  position: absolute;
  transform: translate(-50%, -50%);
}

.hour__current {
  color: #197bbd;
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
}

.action {
  border-radius: 2px;
  border-width: 2px;
  font-size: 13px;
  font-weight: 600;
  height: 34px;
  justify-content: center;
  line-height: 1;
  text-decoration-line: underline;
  width: 100%;
}

.actionsPopover {
  top: -58px !important;
}

.clickableBg {
  cursor: pointer;
  height: 100%;
  width: 100%;

  &:hover {
    background-color: #eff0f357;
  }
}

.touched {
  align-items: center;
  background: #80808008;
  border: 1px dashed #197bbd;
  color: #197bbd;
  display: flex;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  width: 100%;
}
