.currentPackage {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 9px 0 rgba(41, 45, 52, 0.07);
  display: flex;
  flex-direction: column;
  height: 138px;
  justify-content: space-between;
  max-width: 388px;
  padding: 19px 9px 10px 19px;

  & > div {
    display: flex;
    justify-content: space-between;
  }

  .periodTitle {
    color: #292d34;
    font-size: 16px;
    font-weight: 500;
  }

  .price {
    color: #000;
    font-size: 35px;
    font-weight: 500;
    margin-right: 4px;
    text-align: right;
  }

  .month {
    color: #909498;
    font-size: 10px;
    font-weight: 500;
  }

  .subscriptionInfo {
    font-size: 12px;
    line-height: 150%;
  }

  .subscriptionInfo_title {
    color: #7c828d;
  }
}

.packages {
  align-items: stretch;
  border: 1px solid #dcdfe4;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: max-content;
}

.package {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.package__next {
  background: rgba(245, 245, 245, 0.20);
  border-top: 1px solid rgba(171, 171, 171, 0.20);
  composes: package;
}

.packageHeader {
  align-items: flex-start;
  border-bottom: 1px solid rgba(171, 171, 171, 0.2);
  color: var(--black, #292d34);
  display: flex;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  gap: 10px;
  line-height: normal;
  padding: 6px 24px;
  width: 100%;
}

.packageDetails {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 24px;
}

.packageLabel {
  color: #7c828d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.packageValue {
  color: var(--black, #292d34);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (min-width: 768px) {
  .packages {
    align-items: flex-start;
    flex-direction: row;
  }

  .package__next {
    border-top: none;
    border-left: 1px solid rgba(171, 171, 171, 0.20);
  }
}
