.root {
  align-items: center;
  color: #909498;
  display: flex;
  font-size: 12px;
  gap: 8px;
  justify-content: stretch;
  line-height: 18px;
  width: 250px;
}

.line {
  border-top: 1px solid #dddddd;
  height: 1px;
  width: 100%;
}
