.week {
  display: flex;
}

.weekDay {
  border-color: #e9ebf0;
  border-style: solid;
  border-width: 0 0 1px 1px;
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  height: 28px;
  line-height: 20px;
  padding: 4px 0;
  text-align: center;

  &:first-of-type {
    border-left-color: transparent;
  }
}

.day {
  border-color: #e9ebf0;
  border-style: solid;
  border-width: 0 0 1px 1px;
  color: #909498;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 13px;
  font-weight: 500;
  height: 85px;
  justify-content: space-between;
  line-height: 20px;
  padding: 4px 6px;

  &:first-of-type {
    border-left-color: transparent;
  }
}

.day__thisMonth {
  color: #292d34;
}

.day__today {
  & > :first-child {
    color: #197bbd;
    font-size: 1.3em;
    font-weight: 700;
    text-decoration: underline;
  }
}

.events {
  column-gap: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 8px;
}

.event {
  align-items: center;
  border-radius: 10px;
  color: #ffffff;
  display: flex;
  flex: 1;
  font-size: 28px;
  justify-content: center;
  max-width: 50px;
}

.lesson {
  color: #91b5ac;
  composes: event;
  font-weight: 700;
}

.timeSlot {
  color: #197bbd;
  composes: event;
  font-weight: 700;
}

.regularEvent {
  color: rgba(41, 45, 52, 0.8);
  composes: event;
  font-weight: 700;
}

@container (min-width: 500px) {
  .weekDay {
    font-size: 13px;
    padding: 4px 16px;
  }

  .day {
    padding: 4px 10px;
  }

  .events {
    gap: 8px;
    justify-content: end;
    flex-wrap: nowrap;
  }

  .event {
    aspect-ratio: 1 / 1;
    font-size: 22px;
  }

  .lesson {
    background: #91b5ac;
    color: #ffffff;
  }

  .timeSlot {
    background: #197bbd;
    color: #ffffff;
  }

  .regularEvent {
    background: rgba(41, 45, 52, 0.8);
    color: #ffffff;
  }
}
