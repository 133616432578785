.page {
  background-color: #fdfdfd;
  display: grid;
  grid-template-rows: 49px auto;
  padding: 10px;
  row-gap: 10px;
}

.header {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 49px;
  justify-content: end;
  padding: 10px 0;
}

.fileInput {
  opacity: 0;
  visibility: hidden;
  width: 0;
}

.footer {
  align-items: center;
  background: #f2f2f2;
  border-top: 1px solid #dcdfe4;
  display: flex;
  gap: 10px;
  min-height: 40px;
  padding: 0 12px;
}

.footer_action {
  align-items: center;
  color: #292d34;
  composes: btn-icon from "/src/index.scss";
  display: flex;
  font-size: 14px;
  font-weight: 500;
  gap: 6px;
  padding: 0 12px;
}

.empty {
  display: flex;
  padding: 61px 24px;
  position: relative;
}

.empty_texts {
  margin-right: 10%;
  width: 552px;
}

.empty_title {
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 20px;
}

.empty_text {
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 21px;
}

.empty_img {
  position: relative;
  top: 50px;
}

@media (max-width: 769px) {
  .page {
    :global {
      .Materials {
        display: flex;
        flex-direction: column;
      }

      .Materials_FoldersList {
        display: none;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
    }
  }
}
