.root {
  background: #ffffff;
  border: 1px solid #dcdfe4;
  border-radius: 2px;
  display: grid;
  grid-template-columns: 200px minmax(0, 1fr);
  grid-template-rows: 50px 1fr 40px;
  min-height: 400px;
}

.FoldersList {
  grid-column: 1;
  grid-row: 1 / span 3;
}

.header {
  align-items: center;
  border-bottom: 1px solid #eff0f3;
  display: flex;
  justify-content: space-between;
  padding: 10px 25px 10px 15px;

  .search {
    width: 300px;
  }
}

.main {
  .main_top {
    align-items: center;
    border-bottom: 1px solid #eff0f3;
    display: flex;
    padding: 8px 15px;
  }

  .navigationChips {
    cursor: default;
    font-weight: 400;
    gap: 4px;
    height: 20px;
    padding-left: 4px;
    padding-right: 4px;
  }

  .navigationChips_navigate {
    cursor: pointer;
  }

  .main_list {
    display: flex;
    flex-direction: column;
    padding: 8px 15px;

    .list_header {
      align-items: center;
      display: grid;
      font-size: 12px;
      font-weight: 600;
      grid-template-columns: 25px 1fr 40px;
      margin-bottom: 8px;
      padding: 2px;
    }

    .list_items {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding: 0 0 40px;
    }

    .list_itemName {
      align-items: center;
      display: flex;
      gap: 6px;
    }

    .list_item__folder {
      align-items: center;
      border-bottom: 1px solid rgba(239, 240, 243, 0.5);
      cursor: pointer;
      display: grid;
      font-size: 14px;
      grid-template-columns: 25px 1fr 40px;
      line-height: 21px;
      padding: 2px;
      width: 100%;

      &:hover {
        background: rgba(25, 123, 189, 0.02);
      }
    }
  }
}

.condensed {
  grid-template-columns: 150px minmax(0, 1fr);
  grid-template-rows: 1fr 40px;

  .header {
    display: none;
  }

  .list_header,
  .list_item__folder {
    grid-template-columns: 25px 1fr 40px !important;
  }
}

.spinner {
  span {
    border-color: rgb(0 0 0 / 70%) rgb(0 0 0 / 70%) transparent !important;
  }

  :global {
    .Spinner_backdrop {
      display: none !important;
    }
  }
}

@media (min-width: 768px) {
  .list_header,
  .list_item__folder {
    grid-template-columns: 25px 3fr repeat(1, 1fr) 40px !important;
  }
}
