.root {
  background-color: #41454c;
  border: 1px solid #ffffff;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 11px;
  height: 100%;
  justify-content: space-between;
  padding: 4px 6px;
  position: absolute;
  transition: opacity 0.3s;
  width: 100%;
  z-index: 1;

  &:hover {
    opacity: 0.8;
  }
}

.hours {
  color: #292d34;
  display: none;
  font-size: 12px;
  font-weight: 600;
  margin-left: auto;
  position: absolute;
  right: 3px;
  top: 3px;
}

.desc {
  overflow: hidden;
  word-break: break-word;
}

@container (min-width: 180px) {
  .root {
    font-size: 12px;
  }
}

@container (min-width: 220px) {
  .root {
    background-color: #ffffff;
    border: 1px solid rgba(41, 45, 52, 0.8);
    border-left: 9px solid rgba(41, 45, 52, 0.8);
    color: #292d34;
    font-size: 13px;
    width: 90%;
  }

  .hours {
    display: block;
  }

  .desc {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    max-width: 60%;
    overflow: hidden;
    word-break: normal;
  }
}
