.root {
  position: relative;
}

.spinner {
  align-items: center;
  bottom: 0;
  cursor: progress;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.backdrop {
  background-color: rgb(0 0 0 / 10%);
  bottom: 0;
  cursor: progress;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
