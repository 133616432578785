.header {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 12px;
}

.picker {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  margin-top: 16px;

  :global {
    .TemplatePicker {
      flex: 1;
    }
  }
}

.subHeader {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin-top: 16px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 10px;
}

.templatesCounter {
  height: 20px;
  width: 20px;
}

.noTemplatesInfo {
  font-size: 14px;
  font-style: italic;
}
