.info {
  display: grid;
  font-size: 14px;
  grid-template-columns: 100px 1fr;
  line-height: 17px;
  padding-bottom: 10px;
  padding-top: 10px;
  row-gap: 10px;
}

.label {
  color: rgba(41, 45, 52, 0.9);
}

.materials {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  row-gap: 2px;
}

.datepicker {
  cursor: pointer;
  max-height: 100%;
  max-width: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;

  * {
    cursor: pointer;
  }
}
