.root {
  align-items: center;
  background: #ffffff;
  border: 1px solid rgba(233, 235, 240, 0.5);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  gap: 14px;
  line-height: 21px;
  padding: 4px 16px;

  & > img {
    flex-shrink: 0;
  }

  & > * {
    min-width: 0;
  }
}

.topInfo {
  align-items: center;
  color: #828588;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  gap: 6px;
  line-height: 18px;
  margin-bottom: 2px;

  & > * {
    flex-shrink: 0;
  }
}

.students {
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.topicContainer {
  align-items: center;
  display: flex;
  gap: 6px;

  .topic {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .deadlineChips {
    cursor: help;
    flex-shrink: 0;
    font-size: 12px;
    font-weight: 400;
    height: 18px;
    line-height: 1;
    padding: 0 2px;
  }
}
