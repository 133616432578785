.contentRoot {
  width: 250px;
}

.header {
  border-bottom: 1px solid #e3e3e3;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 8px;
}

.contactInfo {
  display: grid;
  font-size: 14px;
  grid-template-columns: 70px 1fr;
  padding-bottom: 10px;
  padding-top: 10px;
  row-gap: 8px;
}

.footer {
  align-items: center;
  border-top: 1px solid #e3e3e3;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  gap: 12px;
  justify-content: flex-end;
  padding: 8px 0;
}

.showMoreBtn {
  align-items: center;
  color: #292d34;
  composes: btn-icon from "/src/index.scss";
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
  text-decoration-line: underline;
}

.email {
  composes: link from "/src/index.scss";
  overflow: hidden;
  text-overflow: ellipsis;
}
