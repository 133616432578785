.root {
  align-items: center;
  color: #292d34;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  gap: 6px;
  line-height: 21px;
  padding: 0;
}
