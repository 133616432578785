$single_item_height: 30px;

.root {
  min-width: 0;
  position: relative;
}

.icon {
  left: 8px;
  position: absolute;
  top: 54%;
  transform: translateY(-50%);
}

.withLabel {
  .icon {
    top: 38px;
  }
}

.input {
  padding-left: 30px;
}

.list {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 6px 12px;

  &:empty {
    padding: 0;
  }
}

.listItem {
  align-items: center;
  border-bottom: 1px solid rgba(242, 242, 242, 0.56);
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-size: 14px;
  gap: 8px;
  line-height: 21px;
  min-height: $single_item_height;
  width: 100%;

  &:hover {
    background-color: #eff0f357;
  }

  :global {
    .Checkbox {
      width: 100%;
    }
  }
}

.popover {
  max-height: 176px;
  overflow-y: auto !important;
  padding: 0;
}
