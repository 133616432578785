.root {
  background: #ffffff;
  border: 1px solid rgba(242, 242, 242, 0.56);
  border-radius: 0;
  box-shadow: 0 3px 5px rgb(0 0 0 / 15%);
  min-width: 180px;
  padding: 12px 16px;
}

.title {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 8px;
}

.options {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.option {
  border-bottom: 1px solid rgba(242, 242, 242, 0.56);
  cursor: pointer;
  font-size: 14px;
  padding: 4px 6px;
  transition: all 0.3s ease;
  width: 100%;

  &:hover {
    background-color: #eff0f357;
  }
}
