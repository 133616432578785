.root {
  align-items: center;
  background: #197bbd;
  border-radius: 12px;
  color: #ffffff;
  display: inline-flex;
  font-size: 13px;
  font-weight: 500;
  gap: 10px;
  height: 20px;
  line-height: 1;
  padding: 1px 8px;
}

.outline {
  background: #ffffff;
  border: 1px solid #197bbd;
  color: #197bbd;
}

.dashed {
  background: #ffffff;
  border: 1px dashed #197bbd;
  color: #197bbd;
}
