.root {
  position: relative;
}

.icon {
  left: 8px;
  position: absolute;
  top: 54%;
  transform: translateY(-50%);
}

.withLabel {
  .icon {
    top: 38px;
  }
}

.input {
  padding-left: 30px;
}

.list {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.listItem {
  border-bottom: 1px solid #d8d8d8;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 8px;
  line-height: 21px;
  min-height: 30px;
  padding-bottom: 6px;
  padding-left: 4px;
  width: 100%;

  &:hover {
    background-color: #eff0f357;
  }

  :global {
    .Pill {
      border-radius: 3px;
    }
  }
}

.plan {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto 40px;
}

.popover {
  max-height: 250px;
  overflow-y: auto !important;
  padding-left: 10px;
  padding-right: 10px;
}

.templatesList {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.template {
  align-items: center;
  display: grid;
  grid-template-columns: 16px 1fr;
}

.templateName {
  background: #f6f5f5;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  font-size: 13px;
  line-height: 21px;
  padding: 1px 6px;
}

.templateCounter {
  font-size: 12px;
}

.templatesCounter {
  padding-left: 6px;
  padding-right: 6px;
}
