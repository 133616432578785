.root {
  max-width: 450px;
}

.header {
  border-bottom: 1px solid #e3e3e3;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 8px;
}

.info {
  display: grid;
  font-size: 14px;
  grid-template-columns: 100px 1fr;
  line-height: 17px;
  padding-bottom: 10px;
  padding-top: 10px;
  row-gap: 8px;
}

.label {
  color: rgba(41, 45, 52, 0.9);
}

.materials {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
}

.footer {
  align-items: center;
  border-top: 1px solid #e3e3e3;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  gap: 12px;
  justify-content: flex-end;
  padding: 8px 0;
}
