.page {
  background-color: #fdfdfd;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr auto;
  grid-template-rows: 49px auto;
  padding: 10px;

  :global {
    .Table {
      table {
        table-layout: fixed;
      }
    }
  }
}

.header {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  grid-column: 1 / 2;
  height: 49px;
  justify-content: space-between;
  padding: 10px 0;

  :global {
    .Button {
      flex-shrink: 0;
    }
  }
}

.table {
  grid-column: 1 / 2;
}

.templatesCounter {
  padding-left: 6px;
  padding-right: 6px;
}

.sidePanelSection {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 129px minmax(0, 1fr);
  padding: 8px 0 12px;
  row-gap: 10px;
}

.sidePanelSection__main {
  border-bottom: 1px solid rgba(242, 242, 242, 0.56);
  composes: sidePanelSection;
  margin-bottom: 16px;
}

.templates_header {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 12px;
}

.templates_picker {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  margin-top: 16px;

  :global {
    .TemplatePicker {
      flex: 1;
    }
  }
}

.templates_subHeader {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin-top: 16px;
}

.templates_list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 10px;
}

.Template {
  align-items: center;
  display: flex;
  gap: 10px;
}

.Template_name {
  background: #f6f5f5;
  border-radius: 5px;
  flex: 1;
  font-size: 13px;
  line-height: 21px;
  padding: 1px 6px;
}

.Template_counter {
  display: inline-block;
  width: 12px;
}

.detailsModalBody {
  outline: none;
}

@media (min-width: 768px) {
  .page {
    :global {
      .Table {
        table {
          table-layout: auto;
        }
      }
    }
  }
}
