.modal {
  min-width: 350px;
  width: 350px;
}

.row {
  align-items: flex-end;
  display: flex;
  gap: 12px;

  :global(.InputRoot) {
    width: 100%;

    input {
      cursor: pointer;
      text-overflow:ellipsis
    }
  }
}
