.future {
  background: #40bc86;
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
  height: 12px;
  position: relative;
  width: 12px;
}

.past {
  background: #828588;
  composes: future;
}

.small {
  height: 6px;
  width: 6px;
}
