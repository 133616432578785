.node {
  border: 2px solid #909498;
  border-radius: 50%;
  height: 36px;
  position: relative;
  width: 36px;

  &::after {
    background-color: #909498;
    border-radius: 50%;
    content: "";
    display: block;
    height: 11px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 11px;
  }
}

.node__current {
  border-color: #60adc6;

  &::after {
    background-color: #60adc6;
    height: 14px;
    width: 14px;
  }
}

.node__with_date {
  border-color: #60adc6;

  &::after {
    background-color: #ffffff;
    border: 2px solid #60adc6;
  }
}

.node__no_date {
  border-color: #60adc6;

  &::after {
    display: none;
  }
}
