.filters {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 16px;
}

.listItem {
  align-items: center;
  background: #ffffff;
  border: 1px solid rgba(233, 235, 240, 0.5);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  display: none;
  font-size: 15px;
  gap: 24px;
  grid-template-columns: 1fr 1fr 0.8fr 0.8fr auto;
  justify-items: start;
  padding: 4px 22px;

  @media (min-width: 768px) {
    display: grid;
  }

  .participant {
    cursor: pointer;
    font-size: 14px;
    transition: all 150ms;
    word-break: break-all;

    &:hover {
      color: #197bbd;
      text-decoration: underline;
    }
  }
}

.listItemMobile {
  composes: listItem;
  display: grid;
  font-size: 14px;
  gap: 12px;
  grid-template-columns: 1.5fr 1fr auto;
  padding: 8px 22px 8px 12px;

  @media (min-width: 768px) {
    display: none;
  }
}
