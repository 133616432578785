.header {
  align-items: center;
  color: #292d34;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  gap: 2px;
  line-height: 20px;
  margin-bottom: 8px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.material {
  align-items: center;
  color: #292d34;
  display: grid;
  gap: 10px;
  grid-template-columns: minmax(0, 1fr) auto auto;
}

.action {
  align-items: center;
  border: 1px dashed #197bbd;
  border-radius: 100px;
  color: #197bbd;
  composes: btn-icon from "/src/index.scss";
  display: flex;
  font-size: 18px;
  height: 20px;
  justify-content: center;
  line-height: 1;
  margin-left: 6px;
  width: 26px;
}

.noMaterialsInfo {
  font-size: 14px;
  font-style: italic;
}
