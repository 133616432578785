.page {
  display: flex;
  flex-direction: column;
}

.header {
  align-items: center;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.header_actions {
  align-items: center;
  display: flex;
  gap: 16px;
  min-width: fit-content;

  :global {
    .btn-icon {
      align-items: center;
      height: 25px;
    }
  }
}

.goToBtn {
  align-items: center;
  border: 1px solid #292d34;
  border-radius: 5px;
  composes: btn-icon from "/src/index.scss";
  display: flex;
  font-size: 14px;
  font-weight: 500;
  gap: 10px;
  height: 37px;
  justify-content: center;
  width: 100%;
}

.goToFinances {
  composes: goToBtn;
  margin-top: 12px;

  svg {
    transform: rotate(90deg);
  }
}

.goToCalendar {
  composes: goToBtn;
  margin-top: 8px;

  svg {
    transform: rotate(270deg);
  }
}

.mainSection {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 20px 16px;
}

.sectionHeader {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  gap: 6px;
  line-height: 24px;
  user-select: none;
}

.calendarSection {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding: 16px;
}

.tabs {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 12px;
}

.tab {
  border-bottom: 2px solid transparent;
  color: #909498;
  cursor: pointer;
  gap: 10px;
  height: 32px;
  transition: color 0.3s;
  width: 63px;

  &:hover {
    color: darken(#909498, 20%);
  }
}

.tab__active {
  border-color: #197bbd;
  color: #292d34;
  composes: tab;

  &:hover {
    color: #292d34;
  }
}

.lessonSubLists {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (min-width: 767px) {
  .scrollToCalendarSection {
    display: none;
  }
}

@media (min-width: 1200px) {
  .page {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 447px;
  }

  .mainSection {
    gap: 22px;
    padding: 22px;
  }

  .calendarSection {
    background: #fdfdfd;
    border-left: 1px solid #e9ebf0;
    padding: 22px 33px;
  }
}
