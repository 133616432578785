.container {
  background-color: white;
  bottom: 0;
  box-shadow: -4.1px 8.2px 16px hsl(0deg 0% 0% / 37%);
  position: fixed;
  right: -300px;
  top: 0;
  transition: right 0.3s ease-in-out;
  width: 280px;
  z-index: 9999;
}

.backdrop {
  background-color: #00000030;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.1s ease;
  z-index: 9998;
}

.mobileMenu {
  height: auto;
  max-height: 100%;
  min-height: unset;
  position: static;
  width: auto;
}
