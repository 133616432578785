.modal {
  width: 900px;

  :global {
    .ModalContent {
      padding: 0;
    }
  }
}

.body {
  display: grid;
  grid-template-columns: 1fr 202px;
}

.selected {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
}

.selected_header {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}

.selected_list {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  gap: 6px;
  max-width: 100%;
  width: 100%;
}

.selected_listItem {
  align-items: center;
  border-bottom: 1px solid rgba(239, 240, 243, 0.4);
  display: flex;
  gap: 8px;
  height: 24px;
  padding: 2px 0;
}

.selected_listItemName {
  margin-right: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.empty {
  padding: 61px 24px;
  position: relative;
  text-align: center;
}

.empty_title {
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 310px;
  text-align: center;
}

.empty_addFirst {
  margin-top: 40px;
}

.noMaterialsSelected {
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
}

@media (max-width: 769px) {
  .modal {
    :global {
      .Materials {
        display: flex;
      }

      .Materials_FoldersList {
        display: none;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
    }
  }
}
