$hour_part_width: 37px;

.root {
  background: #ffffff;
  border: 1px solid #e9ebf0;
  max-width: 100%;
  width: 100%;
}

.header {
  align-items: center;
  border-bottom: 1px solid #e9ebf0;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 16px;
}

.header__title {
  font-size: 22px;
  line-height: 33px;
  user-select: none;
}

.header__date {
  color: #909498;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
}

.subheader {
  align-items: center;
  display: flex;
  gap: 14px;
  justify-content: center;
  padding: 12px 16px;

  button {
    font-size: 14px;
    font-weight: 500;
  }
}

.days {
}

.day {
  border-top: 1px solid #e9ebf0;
  display: flex;
  position: relative;
}

.day__hour {
  border-right: 1px solid #e9ebf0;
  flex-shrink: 0;
  height: 60px;
  position: relative;
  text-align: center;
  width: $hour_part_width;
}

.day__content {
  container: scheduler-cell / inline-size;
  flex: 1;
  height: 60px;
  max-width: calc(100% - $hour_part_width);
  position: relative;
}

.hour {
  background: white;
  display: inline-block;
  font-size: 12px;
  line-height: 12px;
  padding: 0 2px;
  position: absolute;
  transform: translate(-50%, -50%);
}

.hour__current {
  color: #197bbd;
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
}

.action {
  border-radius: 2px;
  border-width: 2px;
  font-size: 13px;
  font-weight: 600;
  height: 34px;
  justify-content: center;
  line-height: 1;
  text-decoration-line: underline;
  width: 100%;
}

.actionsPopover {
  top: -58px !important;
}

.clickableBg {
  cursor: pointer;
  height: 100%;
  width: 100%;

  &:hover {
    background-color: #eff0f357;
  }
}

.touched {
  align-items: center;
  background: #80808008;
  border: 1px dashed #197bbd;
  color: #197bbd;
  display: flex;
  font-size: 32px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  width: 100%;
}
