.root {
  color: #7c828d;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  gap: 25px;
  line-height: 24px;
  padding: 10px;
}

.tab {
  border-bottom: 2px solid transparent;
  cursor: pointer;
  padding-bottom: 8px;
}

.tab__active {
  border-bottom-color: #197bbd;
  color: #292d34;
  composes: tab;
  cursor: default;
}
