.modal {
  width: 443px;
}

.planSection {
  padding-bottom: 10px;
}

.templatesSection {
  align-items: flex-start;
  border-top: 1px solid #f2f2f2;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 10px 10px 0;
}

.templatesSection_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 4px;
}

.templatesSection_noTemplates {
  color: #909498;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
}
