.root {
  align-items: center;
  display: flex;
  gap: 10px;
  width: 100%;
}

.name {
  background: #f6f5f5;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  font-size: 13px;
  line-height: 21px;
  padding: 1px 6px;
}

.counter {
  display: inline-block;
  width: 12px;
}

.reorderBtns {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.rotate {
  composes: btn-icon from "/src/index.scss";
  transform: rotate(180deg);
  transform-origin: center center;
}
