.root {
  align-items: center;
  background-image: url("./img/bg-lines.png");
  background-position: center right;
  background-repeat: repeat-x;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  margin: 50px 0;
  min-height: 100vh;
  padding: 0 15px;
}
