.root {
}

.container {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  gap: 10px;
  padding: 10px 14px;
  z-index: 9999;
}

.square {
  border-radius: 0;
  margin-left: 2px;
}
