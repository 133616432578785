.root {
}

.title {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 12px;
}

.list {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 4px;
  line-height: 21px;
}

.list_item {
  align-items: center;
  display: flex;
  gap: 12px;
}
