.root {
}

.modal {
  min-width: unset;
  width: 330px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.checkboxRow {
  align-items: center;
  display: flex;
  gap: 8px;
  margin-top: 8px;

  :global(.Checkbox) {
    font-size: 13px;
    font-weight: 500;
  }
}
