.root {
}

.option {
  border-bottom: 1px solid rgba(242, 242, 242, 0.56);
  padding: 4px 0;

  &:hover {
    background: rgba(25, 123, 189, 0.02);
  }
}

.noStudentsInfo {
  font-size: 14px;
  font-style: italic;
  line-height: 1.3;
  max-width: 200px;
}
