.Greeting {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  user-select: none;
}

@media (min-width: 1200px) {
  .Greeting {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  }
}
