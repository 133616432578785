.root {
  padding: 32px 12px;
}

.sectionTitle {
  background: #ffffff;
  border: 1px solid #dcdfe4;
  border-radius: 5px;
  color: #292d34;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 40px;
  margin-left: 30px;
  padding: 2px 24px;
}

.tree {
  display: flex;
  flex-direction: column;
}

.row {
  align-items: center;
  display: grid;
  grid-template-columns: 90px 36px 1fr;
}

.nodeConnection {
  width: 2px;
}

.time {
  align-items: flex-end;
  color: #828282;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: 36px;
  justify-content: center;
  line-height: 1.4;
  padding-right: 6px;
  text-align: right;
}

.lessonContainer {
  max-height: 36px;
  position: relative;
  z-index: 1;

  &:hover {
    .lessonActions {
      display: flex;
    }
  }
}

.lesson {
  background: #ffffff;
  border: 1px solid #dcdfe4;
  font-size: 13px;
  margin-left: 6px;
  max-height: 71px;
  min-height: 26px;
  overflow: hidden;
  padding: 4px 8px;
}

.lesson_topic {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
}

.lesson_noTopic {
  color: #bbbbbb;
  font-style: italic;
}

.lessonActions {
  align-items: center;
  background: #ffffff;
  border-color: #dcdfe4;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  display: none;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
  margin-left: 6px;
  padding: 4px 8px 4px 12px;
}

.action {
  align-items: center;
  border: 1px dashed #000000;
  border-radius: 50px;
  composes: btn-icon from "/src/index.scss";
  cursor: pointer;
  display: flex;
  height: 22px;
  justify-content: center;
  width: 22px;

  path {
    fill: #000000;
  }
}

.nodeShowMore {
  border: 2px dotted #7c828d;
  border-radius: 50%;
  color: #7c828d;
  cursor: pointer;
  height: 36px;
  position: relative;
  width: 36px;

  & > svg {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.actionText {
  color: #7c828d;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  margin-left: 8px;
  text-decoration-line: underline;
}

.assignDateText {
  composes: actionText;
  margin-left: 0;
  margin-right: 8px;
  text-align: right;
}

.datePicker {
  display: block;
  height: 0;
  overflow: hidden;
}

.spinner {
  :global {
    .Spinner_backdrop {
      background-color: rgb(191 191 191 / 10%);
      z-index: 2;
    }

    .Spinner > span {
      border-color: rgb(0 0 0 / 58%) rgb(0 0 0 / 58%) transparent !important;
    }
  }
}

@media (min-width: 1200px) {
  .root {
    padding: 30px 30px 30px 0;
  }

  .row {
    align-items: center;
    display: grid;
    grid-template-columns: 130px 36px 230px;
  }

  .time {
    font-size: 13px;
  }

  .lesson {
    font-size: 14px;
  }
}
