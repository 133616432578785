.modal {
  font-weight: 400;
  width: 600px;

  :global {
    .ModalContent {
      padding: 0;
    }
  }
}

.split {
  display: flex;
  flex-direction: column;
}

.section {
  border-bottom: 1px solid #eff0f3;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 0;
  padding: 16px 24px;
}

.studentAndDate {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.plansSection {
  padding: 16px 24px 32px;
}

.prices {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 4px;

  path {
    fill: #197bbd;
  }
}

.label {
  align-items: center;
  composes: label from "/src/index.scss";
  display: flex;
  gap: 4px;
}

.addFirstStudentBtn {
  color: #197bbd;
  composes: btn-icon from "/src/index.scss";
  font-weight: 600;
  margin-top: 2px;
}

.hiddenStudentValidator {
  height: 0;
  left: -100px;
  opacity: 0;
  position: relative;
  top: 5px;
  width: 0;
}

.isFixed_checkbox {
  font-weight: 500;
}

.isFixed_weeks {
  align-items: center;
  display: flex;
  font-size: 13px;
  gap: 6px;
  padding-top: 6px;
}

.isFixed_input {
  caret-color: transparent;
  padding-bottom: 1px;
  padding-top: 1px;
  width: 27px;
}

.isFixed_dropdown {
  min-width: 50px;
  padding-left: 4px;
  padding-right: 4px;
  text-align: center;
  width: 50px;
}

@media (min-width: 768px) {
  .studentAndDate {
    display: grid;
    gap: 50px;
    grid-template-columns: 1fr 1fr;
  }

  .split {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
