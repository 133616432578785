.page {
  background-color: #fdfdfd;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;

  :global {
    .Table {
      table {
        table-layout: fixed;
      }
    }
  }
}

.header {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 49px;
  justify-content: space-between;
  padding: 10px 0;

  :global {
    .Button {
      flex-shrink: 0;
    }
  }
}

.studentNameCol {
  composes: btn-icon from "/src/index.scss";
  text-decoration-color: #292d3459;
  transition: all 0.2s;

  &:hover {
    filter: brightness(0);
    opacity: 1;
    text-decoration-line: underline;
  }
}

.showMoreBtn {
  color: #292d34;
  composes: btn-icon from "/src/index.scss";
  font-size: 11px;
  font-weight: 500;
  text-decoration-line: underline;
}

.contact {
  align-items: center;
  display: flex;
  gap: 5px;

  span {
    user-select: all;
  }
}
