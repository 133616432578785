.text {
  font-size: 22px;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: calc(50% - 150px);
}

@media (min-width: 768px) {
  .text {
    font-size: 24px;
    margin-left: 30px;
    margin-right: 30px;
    top: calc(50% - 130px);
  }
}
