@import-normalize;

:root {
  --toastify-color-error: #b24b43;
  --toastify-color-info: #3498db;
  --toastify-color-success: #91b5ac;
  --toastify-color-warning: #f1c40f;
  --toastify-font-family: "Poppins", sans-serif;
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  color: #292d34;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  min-height: 100vh;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 !important;
}

input,
textarea {
  font-family: "Poppins", sans-serif;
}

ul,
ol {
  margin: 0;
  padding-left: 15px;
}

ul {
  padding-left: 17px;
}

button {
  font-family: "Poppins", sans-serif;
}

a {
  color: #197bbd;
  text-decoration: none;
}

p {
  margin: 0;
}

/* helpers */
.link {
  color: #197bbd;
  cursor: pointer;
  font-weight: 500;
  transition: opacity 0.3s;
}

.link:hover {
  opacity: 0.7;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

.f-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.v-center {
  align-items: center;
  display: flex;
}

.btn-icon {
  background: transparent;
  border: 0;
  color: #292d34;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  padding: 0;
  transition: opacity 0.3s;
}

.btn-icon:hover {
  opacity: 0.8;
}

.text-center {
  text-align: center;
}

.red {
  color: #b24b43;
}

.label {
  color: #292d34;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.clickable {
  cursor: pointer;
}

.trim-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fullViewSpinner {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  span {
    border-color: rgb(0 0 0 / 70%) rgb(0 0 0 / 70%) transparent !important;
  }

  .Spinner_backdrop {
    display: none !important;
  }
}

.width-100 {
  width: 100%;
}

// RWD
.hide-desktop-down {
  @media (max-width: 1199px) {
    display: none !important;
  }
}

.hide-desktop-up {
  @media (min-width: 1200px) {
    display: none !important;
  }
}

.hide-mobile {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.hide-mobile-up {
  @media (min-width: 768px) {
    display: none !important;
  }
}

// END - RWD

// INTRO.JS
.introjs-tooltipReferenceLayer {
  * {
    font-family: "Poppins", sans-serif;
  }

  .introjs-tooltip {
    min-width: 300px;
  }

  .introjs-tooltip-title {
    font-weight: 600;
  }

  .introjs-progressbar {
    background-color: #197bbd;
  }

  .introjs-button {
    align-items: center;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    cursor: pointer;
    display: inline-flex;
    font-weight: 500;
    gap: 6px;
    outline: none;
    text-shadow: none;
  }

  .introjs-nextbutton {
    background: #91b5ac;
    color: #ffffff;
  }

  .introjs-prevbutton {
    background: #909498;
    color: #ffffff;

    &.introjs-disabled {
      opacity: 0;
    }
  }
}

// END - INTRO.JS

// Toastify
.Toastify__toast-body {
  min-width: 0;
}

.Toastify__toast-body > div:last-child {
  min-width: 0;
}

.Toastify__toast-container--bottom-left {
  @media (min-width: 768px) {
    max-width: 500px;
    width: auto;
  }
}

// END - Toastify

//
$spaceamounts: (
  0,
  1,
  2,
  4,
  5,
  6,
  8,
  9,
  10,
  12,
  15,
  16,
  20,
  24,
  25,
  26,
  30,
  32,
  35,
  40,
  45,
  48,
  50,
  75,
  100
);
$sides: (
  "": "all",
  "t": "top",
  "b": "bottom",
  "l": "left",
  "r": "right",
);

@each $space in $spaceamounts {
  @each $prefix, $value in $sides {
    $property: if($prefix == "", "", -#{$value});
    .m#{$prefix}#{$space} {
      margin#{$property}: #{$space}px;
    }
    .p#{$prefix}#{$space} {
      padding#{$property}: #{$space}px;
    }
  }
}
