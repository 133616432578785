.modal {
  min-width: unset;
  width: 300px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
