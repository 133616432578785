.modal {
  min-width: unset;
}

.studentInput {
  input::placeholder {
    color: #292d34;
    opacity: 1;
  }
}

.datePickers {
  display: flex;
  gap: 2px;
  margin-bottom: 20px;
  margin-top: 2px;

  & > * {
    min-width: 0;
  }
}
