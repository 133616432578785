.root {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 0 16px;
  width: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.showMoreBtn {
  align-items: center;
  color: #292d34;
  composes: btn-icon from "/src/index.scss";
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  text-decoration-line: underline;
}

.noTodosInfo {
  font-size: 14px;
  font-style: italic;
}
