.root {
  align-items: center;
  background: rgba(251, 179, 90, 0.1);
  border: 1px solid #e99455;
  border-radius: 12px;
  display: inline-flex;
  font-size: 13px;
  gap: 4px;
  line-height: normal;
  max-width: 100%;
  padding: 2px 8px;
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.downloadBtn {
  color: #197bbd;
  composes: btn-icon from "/src/index.scss";

  svg {
    width: 12px;
  }
}
