#root-portal {
  position: relative;
  z-index: 99999999999999999;
}

.react-datepicker-wrapper {
  .react-datepicker__close-icon {
    bottom: 7px;
    height: auto;
    top: unset;

    &::after {
      background-color: #197bbd;
      font-size: 14px;
      padding: 0;
    }
  }
}

.react-datepicker__input-time-container {
  align-items: center;
  display: flex;

  input {
    min-width: 70px;
    outline: none;
  }
}

.react-datepicker__portal {
  .react-datepicker-time__header,
  .react-datepicker__current-month {
    font-size: 15px;
  }
}

.react-datepicker__navigation {
  top: 6px;
}

.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__day-name {
  line-height: 2rem;
  width: 2rem;
}

.react-datepicker-time__caption {
  color: black !important;
  font-size: 14px;
  margin-right: -10px;
}

@media (max-width: 360px) {
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__day-name {
    line-height: 1.8rem;
    width: 1.8rem;
  }
}

@media (min-width: 768px) {
  .react-datepicker-time__caption {
    font-size: 13px;
  }
}
