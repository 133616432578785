.page {
  background-color: #fdfdfd;
  padding: 10px;
}

.calendar {
  background-color: #ffffff;
  border: 1px solid #e9ebf0;
  container: calendar / inline-size;
}

.header {
  align-items: center;
  border-bottom: 1px solid #e9ebf0;
  color: #292d34;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 16px 12px;
}

.currentDate {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  gap: 8px;
  margin-right: auto;
  width: 180px;
}

.dateNavigator {
  align-items: center;
  color: #292d34;
  display: flex;
  gap: 8px;
}

.dateNavigator_today {
  color: inherit;
  font-size: 14px;
  font-weight: 500;
}

.weekdayNames {
  border-bottom: 1px solid #e9ebf0;
  border-right: 1px solid transparent;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  padding-left: 37px;
  text-align: center;
}

.weekdayNames[data-cols="5"] {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.weekdayName {
  align-items: center;
  border-left: 1px solid #e9ebf0;
  display: flex;
  font-size: 10px;
  font-weight: 500;
  height: 28px;
  justify-content: center;
  text-transform: uppercase;

  &:first-child {
    border-left: 1px solid #e9ebf0;
  }
}

.weekdayName__today {
  color: #197bbd;
  font-weight: 700;
  text-decoration: underline;

  & > div {
    align-items: center;
    background: rgba(25, 123, 189, 0.01);
    border: 1px solid rgba(25, 123, 189, 0.3);
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
}

@container (min-width: 600px) {
  .header {
    padding: 16px 32px;
  }

  .currentDate {
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    width: 320px;
    margin-right: unset;
  }

  .weekdayName {
    font-size: 13px;
    line-height: 20px;
  }
}
