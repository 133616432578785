.title {
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  margin-bottom: 4px;
  text-align: center;
}

.submitBtn {
  justify-content: center;
  width: 110px;
}

.helpText {
  color: #000000;
  font-size: 11px;
  margin-top: 2px;
}

.noAccountText {
  font-size: 13px;
  line-height: 20px;

  :global {
    .link {
      text-decoration: underline;
    }
  }
}
