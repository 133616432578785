.root {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(233, 235, 240, 0.5);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  display: grid;
  gap: 10px;
  grid-template-columns: 18px 1fr auto;
  padding: 4px 16px;
  position: relative;

  .additionalActions {
    display: none;
  }

  :global {
    .StudentPopover_name {
      font-size: 13px;
    }
  }

  &:hover {
    .additionalActions {
      display: flex;
    }
  }
}

.value {
  font-size: 14px;
  user-select: none;
}

.additionalActions {
  align-items: center;
  background-color: #ffffff;
  border-left: 1px solid rgba(242, 242, 242, 0.56);
  display: flex;
  gap: 10px;
  height: 100%;
  padding-left: 10px;
  position: absolute;
  right: 54px;
  z-index: 1;
}

.input {
  background: #ffffff;
  border: 1px solid rgba(214, 214, 214, 0.2);
  border-radius: 4px;
  color: #292d34;
  font-size: 14px;
  outline: none;
  padding: 4px 8px;
  width: 90%;

  &::placeholder {
    color: #aeaeae;
    font-size: 14px;
  }
}

.saveBtn {
  font-size: 12px;
}

.mutableActions {
  align-items: center;
  display: flex;
  gap: 10px;
}

.selectStudentBtn {
  align-items: center;
  composes: btn-icon from "/src/index.scss";
  height: 26px;
  justify-content: center;
  width: 30px;
}

.initials {
  align-items: center;
  border: 1px dashed #197bbd;
  border-radius: 5px;
  color: #197bbd;
  display: flex;
  flex-direction: row;
  font-size: 11px;
  font-weight: 500;
  height: 22px;
  justify-content: center;
  line-height: 16px;
  padding: 0;
  width: 30px;
}
