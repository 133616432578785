.modal {
  min-width: 280px;
  width: 280px;
}

.defaultViewSection {
  border-bottom: 1px solid rgba(242, 242, 242, 0.56);
  margin-bottom: 10px;
  margin-left: -24px;
  margin-right: -24px;
  padding-bottom: 10px;
  padding-left: 24px;
  padding-right: 24px;
}
