.root {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
}

.example {
  color: #292d34;
  font-size: 14px;
  font-style: italic;
  line-height: 21px;
}

.type {
  max-width: 272px;
}

.days {
  width: 100px;
}
