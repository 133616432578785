.root {
}

.expanded {
  .caret {
    transform: rotate(0);
  }
}

.header {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  gap: 6px;
  line-height: 20px;
  padding: 4px 2px;
  transition: color 0.3s;

  &:hover {
    opacity: 0.8;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.caret {
  transform: rotate(-90deg);
  transform-origin: center;
  transition: all 0.1s ease;
}
