.title {
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  margin-bottom: 4px;
  text-align: center;
}

.subtitle {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.submitBtn {
  justify-content: center;
  width: 120px;
}

.forgotPassword {
  text-align: right;

  :global {
    .link {
      color: #292d34;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      text-decoration-line: underline;
    }
  }
}

.noAccountText {
  font-size: 13px;
  line-height: 20px;
  margin-top: 20px;

  :global {
    .link {
      text-decoration: underline;
    }
  }
}
