.root {
  align-items: center;
  background: #ffffff;
  border: 1px solid rgba(233, 235, 240, 0.5);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  gap: 14px;
  line-height: 21px;
  padding: 4px 16px;

  & > img {
    flex-shrink: 0;
  }

  & > * {
    min-width: 0;
  }
}

.topInfo {
  align-items: center;
  color: #828588;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  gap: 6px;
  line-height: 18px;
  margin-bottom: 2px;

  & > * {
    flex-shrink: 0;
  }
}

.students {
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.topicContainer {
  align-items: center;
  display: flex;
  gap: 6px;

  .topic {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .incompleteChips {
    background-color: #ffffff;
    border: 1px solid #e9ebf0;
    cursor: help;
    padding-bottom: 2px;
    padding-top: 2px;
    width: 18px;
  }

  .homeworksChips {
    height: 17px;
    padding: 1px 4px;

    &:hover {
      .downloadHomeworksBtn {
        display: inline-flex;
      }
    }
  }
}

.downloadHomeworksBtn {
  composes: btn-icon from "/src/index.scss";
  display: none;
}
